.pageRequestMain {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--second-bg);
    height: fit-content;
    width: 100%;
    color: white;
}

.pageRequestTitle {
    padding-top: 56px;
    text-transform: uppercase;
    font-size: 40px;
    font-family: "ExtraBold";
}

.pageRequestTitle span {
    font-family: "Th";
}

.pageRequestContent {
    display: flex;
    margin-top: 80px;
    margin-left: 150px;
    width: calc(100% - 150px);
    justify-self: start;
    align-items: start;
    flex: 1;
}

.pageRequestList {
    padding-bottom: 56px;
    width: 40%;
    font-size: 25px;
}

.pageRequestList li {
    padding-bottom: 12px;
}

.pageRequestMain     .decoration {
    z-index: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
}

.pageRequestMain .decoration img {
    width: 60vh;
    height: 60vh;
    top: 10%;
    position: absolute;
    right: 0;
    transform: rotate(-15deg);
}

@media (max-width: 920px) {
    .pageRequestMain {
        height: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--second-bg);
        height: fit-content;
        width: 100%;
        color: white;
    }
    
    .pageRequestTitle {
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-family: "ExtraBold";
    }
    
    .pageRequestTitle span {
        font-family: "Th";
    }
    
    .pageRequestContent {
        display: flex;
        margin-top: 56px;
        margin-left: 16px;
        margin-right: 16px;
        width: calc(100% - 32px);
        justify-self: start;
        align-items: start;
        height: fit-content;
        flex: 1;
    }
    
    .pageRequestList {
        width: 100%;
        font-size: 25px;
    }
    
    .pageRequestList li {
        padding: 12px;
        background: #9073ce4a;
        border: 1px solid #9073ce4a;
        border-radius: 16px;
        text-align: center;
        margin-bottom: 24px;
    }
    
    .pageRequestMain .decoration {
        display: none;
        z-index: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    
    .pageRequestMain .decoration img {
        width: 60vh;
        height: 60vh;
        top: 10%;
        position: absolute;
        right: 0;
        transform: rotate(-15deg);
    }
}