.pageInfoMain {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    background: var(--threed-bg);
    height: fit-content;
    color: var(--second-bg);
}

.pageInfoUpTitle {
    margin-bottom: -30px;
    text-align: center;
    width: 100%;
    font-size: 20px;
}

.pageInfoTitle {
    text-align: center;
    width: 100%;
    font-size: 65px;
    font-family: "ExtraBold";
}

.pageInfoTitleButton {
    justify-content: center;
    align-items: center;
    display: flex;
}

.pageInfoTitleButton button {
    position: absolute;
    right: 100px;
}

.pageInfoContent {
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 25px;
    padding: 10px;
}

.pageInfoContentTile:first-child {
    transform: scale(.8) rotate(-5deg);
    margin-right: -80px;
}

.pageInfoContentTile:last-child {
    transform: scale(.8) rotate(5deg);
    margin-left: -80px;
}

.pageInfoContentTile {
    padding: 20px;
}

.pageInfoContent img {
    width: 42vh;
    height: 60vh;
    object-fit: cover;
    background: var(--second-bg);
    outline: 5px solid var(--accent-color);
    outline-offset: -2px;
    border-radius: 20px;
}

.pageInfoContent p {
    text-align: center;
}

.pageInfoFooter {
    padding: 16px;
    background: var(--accent-color);
    gap: 26px;
    width: fit-content;
    display: flex;
    justify-self: center;
    flex-direction: row;
    font-size: 25px;
    color: black;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.1);
}

@media (max-width: 920px) {
    .pageInfoMain {
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 50px;
        background: var(--threed-bg);
        height: fit-content;
        color: var(--second-bg);
    }

    .pageInfoUpTitle {
        margin-bottom: -30px;
        text-align: center;
        width: 100%;
        font-size: 20px;
    }

    .pageInfoTitle {
        overflow: hidden;
        text-align: center;
        width: 100%;
        font-size: 55px;
        margin-bottom: 16px;
        font-family: "ExtraBold";
    }

    .pageInfoTitleButton {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .pageInfoTitleButton button {
        position: static;
        right: 100px;
    }

    .pageInfoContent {
        display: flex;
        flex: auto;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 20px;
    }

    .pageInfoContentTile:first-child {
        transform: scale(1) rotate(0);
        margin-right: 0;
    }

    .pageInfoContentTile:last-child {
        transform: scale(1) rotate(0);
        margin-left: 0;
    }

    .pageInfoContentTile {
        padding: 20px;
    }

    .pageInfoContent img {
        width: 30vh;
        height: 40vh;
    }

    .pageInfoContent p {
        text-align: center;
    }

    .pageInfoFooter {
        margin: 12px;
        padding: 8px;
        background: var(--accent-color);
        gap: 16px;
        width: fit-content;
        display: flex;
        justify-self: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 18px;
        color: black;
        border-radius: 8px;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.1);
    }

    .pageInfoFooter img {
        width: 32px;
        transform: rotate(90deg);
    }

}