.pageFeedbackMain {
    margin: 38px;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.title {
    text-transform: uppercase;
    font-size: 40px;
    margin-bottom: 38px;
    font-family: "ExtraBold";
}

.pageFeedbackMain .container {
    display: flex;
    flex-direction: column;
    height: 25vh;
    gap: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    width: fit-content;
    height: fit-content;
    background: white;
    padding: 16px 16px 24px 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px rgba(34, 60, 80, 0.2);
    min-height: 250px;
}

.pageFeedbackMain .container .userInfo {
    gap: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pageFeedbackMain .container .userInfo p {
    font-size: 20px;
}

.pageFeedbackMain .container .userInfo div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-family: "Bold";
    background: var(--accent-color);
    font-size: 20px;
}

.pageFeedbackMain .carauselWrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.pageFeedbackMain .carauselWrapper .carausel {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.sliderContainer {
    flex: 1;
    height: fit-content;
    display: flex;
    align-items: center;
}


@media (max-width: 920px) {

    .title {
        text-transform: uppercase;
        font-size: 20px;
        font-family: "ExtraBold";
    }

    .pageFeedbackMain {
        display: flex;
        flex-direction: column;
        height: fit-content;
    }

}