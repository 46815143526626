:root {
  --first-bg: #9073CE;
  --second-bg: #39216D;
  --threed-bg: #D0BAFF;
  --icons-color: #D0BAFF;
  --accent-color: #B5DC23;
}

@font-face {
  font-family: "Regular";
  src: url("./fonts/seed_kr_rg.ttf"), format("truetype");
}

@font-face {
  font-family: "Th";
  src: url("./fonts/seed_jp_th.ttf"), format("truetype");
}

@font-face {
  font-family: "ExtraBold";
  src: url("./fonts/seed_jp_eb.ttf"), format("truetype");
}

@font-face {
  font-family: "Bold";
  src: url("./fonts/seed_kr_bd.ttf"), format("truetype");
}

* {
  margin: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
  background: var(--first-bg);
}

button {
  background: var(--second-bg);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 25px;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.1);
  transition: all 0.1s;
  cursor: pointer;
}

li {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

button:hover {
  background: var(--first-bg);
}