.pageTitleMain {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100vh;
}

.pageTitleContainer {
    margin-left: 25px;
    margin-top: 25px;
    color: white;
    font-size: 13vw;
}


.pageTitleFirst {
    overflow: hidden;
    line-height: 105%;
    font-family: "ExtraBold";
}

.pageTitleSecond {
    overflow: hidden;
    margin-left: 5.5%;
    line-height: 105%;
    font-family: "ExtraBold";
}

.pageTitleSubTitle {
    margin-left: 2%;
    font-size: 2.5vw;
}

.pageTitleSubTitleColored {
    color: var(--accent-color);
}

.pageTitleImageContainer {
    position: absolute;
    right: 10vw;
    bottom: -150px;
    height: fit-content;
    display: inline-flex;
    overflow: visible;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}

@media (max-width: 920px) {
    .pageTitleMain {
        position: relative;
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: 50vh;
    }

    .pageTitleContainer {
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 25px;
        color: white;
        font-size: 15vw;
    }

    .pageTitleFirst {
        width: 100%;
        overflow: hidden;
        line-height: 105%;
        font-family: "ExtraBold";
    }

    .pageTitleSecond {
        overflow: hidden;
        margin-left: 0;
        line-height: 105%;
        font-family: "ExtraBold";
    }

    .pageTitleSubTitle {
        margin-left: 2%;
        font-size: 5vw;
    }

    .pageTitleSubTitleColored {
        color: var(--accent-color);
    }

    .pageTitleImageContainer {
        overflow: visible;
        width: 100vw;
        position: absolute;
        bottom: -30px;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .pageTitleImage {
        width: 40%;
    }

}