.pageResultMain {
    position: relative;
    padding-top: 56px;
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    color: var(--second-bg);
    text-transform: uppercase;
    background: var(--icons-color);
}

.pageResultTitle {
    font-family: "ExtraBold";
    font-size: 50px;
}

.pageResultSubTitle {
    font-family: "Regular";
    font-size: 40px;
}

.pageResultContent {
    flex: 1;
    gap: 90px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    align-content: stretch;
}

.pageResultContentItem {
    z-index: 1;
    display: flex;
    gap: 40px;
    align-items: center;
    padding-top: 32px;
    flex-direction: column;
    height: 600px;
    width: 500px;
    background: var(--accent-color);
    border-radius: 20px;
    border: 5px solid white;
}

.pageResultContentItem:first-child {
    margin-top: 150px;
}

.pageResultContentItem:last-child {
    margin-bottom: 150px;
}


.pageResultContentItem .title {
    font-size: 40px;
    font-family: "ExtraBold";
    text-transform: uppercase;
}

.pageResultContentItem ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 80%;
    font-size: 20px;
    color: black;
}

.pageResultContentItem li {
    font-family: "ExtraBold";
    margin-bottom: 16px;
}

.decoration {
    z-index: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
}

.decoration img {
    position: absolute;
    width: 80vh;
}

.decoration .decoVitamin {
    left: -300px;
    top: -300px;
}

.decoration .decoChild {
    right: -300px;
    bottom: -300px;
}

@media (max-width: 920px) {
    .pageResultMain {
        position: relative;
        padding-top: 56px;
        padding-bottom: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        color: var(--second-bg);
        text-transform: uppercase;
        background: var(--icons-color);
    }

    .pageResultTitle {
        font-family: "ExtraBold";
        z-index: 9999;
        font-size: 30px;
    }

    .pageResultSubTitle {
        font-family: "Regular";
        z-index: 9999;
        font-size: 20px;
    }

    .pageResultContent {
        flex: 1;
        gap: 32px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-content: stretch;
    }

    .pageResultContentItem {
        z-index: 1;
        display: flex;
        margin-left: 16px;
        margin-right: 16px;
        gap: 40px;
        align-items: center;
        padding-top: 16px;
        flex-direction: column;
        height: fit-content;
        width: fit-content;
        background: var(--accent-color);
        border-radius: 20px;
        border: 5px solid white;
    }

    .pageResultContentItem:first-child {
        margin-top: 0;
    }

    .pageResultContentItem:last-child {
        margin-bottom: 0;
    }


    .pageResultContentItem .title {
        font-size: 25px;
        font-family: "ExtraBold";
        text-transform: uppercase;
    }

    .pageResultContentItem ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 80%;
        font-size: 15px;
        color: black;
    }

    .pageResultContentItem li {
        font-family: "ExtraBold";
        margin-bottom: 16px;
    }

    .pageResultContentItem li img {
        width: 20px;
    }

    .decoration {
        z-index: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .decoration img {
        position: absolute;
        width: 80vh;
    }

    .decoration .decoVitamin {
        left: -300px;
        top: -300px;
    }

    .decoration .decoChild {
        right: -300px;
        bottom: -300px;
    }
}