.photoViewerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.5);
    transition: all 0.3s;
}

.photoViewerWrapper img {
    height: 80%;
    border-radius: 8px;
    object-fit: contain;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.1);
}