.boxArrowContainer {
    overflow: visible;
    margin-top: 150px;
    margin-right: -25px;
    width: fit-content;
    height: fit-content;
    transform: rotate(-4deg);
    display: flex;
    flex-direction: row;
}

.boxArrowTitle {
    color: var(--second-bg);
    text-align: center;
    padding: 20px 50px;
    background: white;
    font-size: 30px;
    border-radius: 20px;
}

.boxArrowImage {
    margin-left: -10px;
    margin-top: -90px;
}

@media (max-width: 920px) {
    .boxArrowContainer {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        transform: rotate(0);
        display: flex;
        flex-direction: row;
    }

    .boxArrowContainer img {
        display: none;
    }
    
    .boxArrowTitle {
        color: var(--second-bg);
        text-align: center;
        padding: 10px 30px;
        background: white;
        font-size: 30px;
        border-radius: 20px;
    }
    
    .boxArrowImage {
        margin-left: 0;
        margin-top: 0;
    }
}

@media (max-width: 768px) {

    .boxArrowContainer {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        transform: rotate(0);
        display: flex;
        flex-direction: row;
    }

    .boxArrowContainer img {
        display: none;
    }
    
    .boxArrowTitle {
        color: var(--second-bg);
        text-align: center;
        padding: 10px 30px;
        background: white;
        font-size: 15px;
        border-radius: 20px;
    }
    
    .boxArrowImage {
        margin-left: 0;
        margin-top: 0;
    }

}

