.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #B5DC23;
    height: 100vh;
}

.container * {
    overflow: visible;
    color: var(--second-bg);
    font-family: "Bold";
}

.wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.wrapper a {
    background: white;
    border: none;
    padding: 10px 20px;
    font-size: 25px;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.1);
    transition: all 0.1s;
    cursor: pointer;
    color: var(--second-bg);
    text-decoration: none;
}

a:hover  {
    transform: scale(1.1);   
}

a:active  {
    transform: scale(.9);
}

.wrapper a:hover {
    background: #d9d9d9;
}

.subTitle {
    font-size: 30px;
}

.title {
    margin-top: 56px;
    text-transform: uppercase;
    font-size: 40px;
    font-family: "ExtraBold";
}

@media (max-width: 920px) {

    .title {
        text-transform: uppercase;
        font-size: 20px;
        font-family: "ExtraBold";
    }

}