.activityContainer {
    overflow: hidden;
    font-family: Regular;
    font-size: 25px;
    color: white;
    height: fit-content;
    align-items: center;
    text-align: center;
}

.activityContainer img {
    overflow: hidden;
    width: 230px;
    height: 230px;
    margin-bottom: 40px;
}

@media (max-width: 1400px) {
    .activityContainer {
        font-family: Regular;
        font-size: 20px;
        color: white;
        align-items: center;
        text-align: center;
    }

    .activityContainer img {
        width: 150px;
        height: 150px;
        margin-bottom: 40px;
    }

}

@media (max-width: 800px) {
    .activityContainer {
        font-family: Regular;
        font-size: 16px;
        color: white;
        align-items: center;
        text-align: center;
    }

    .activityContainer img {
        width: 100px;
        height: 100px;
        margin-bottom: 40px;
    }

}