.pageActivityMain {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: auto;
    align-items: center;
    background: var(--second-bg);
}

.pageActivityFirstLine {
    overflow: hidden;
    margin-top: 100px;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: stretch;
}

.pageActivitySecondLine {
    overflow: hidden;
    margin-bottom: 100px;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: stretch;
}

@media (max-width: 1400px) {
    .pageActivityMain {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: auto;
        align-items: center;
        background: var(--second-bg);
        height: 100vh;
    }

    .pageActivityFirstLine {
        margin-top: 50px;
        width: 95%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-content: stretch;
    }

    .pageActivitySecondLine {
        margin-bottom: 50px;
        width: 95%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-content: stretch;
    }
}

@media (max-width: 768px) {
    .pageActivityMain {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: auto;
        align-items: center;
        background: var(--second-bg);
        height: fit-content;
    }

    .pageActivityFirstLine {
        margin-top: 50px;
        margin-bottom: 50px;
        width: 95%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-content: stretch;
    }

    .pageActivitySecondLine {
        margin-bottom: 50px;
        width: 95%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-content: stretch;
    }
}